/* eslint-disable */
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"
import { Dialog } from "@blueprintjs/core"
import ImageGallery from "react-image-gallery"
import Layout from "../components/layout"
import GetStarted from "../components/CTA/GetStarted"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getClientCloudinaryMedia } from "../utils/utils"
import CloseIcon from "../components/CloseIcon"
import { Text, Image } from "../components/Core"

const SingleCommunity = ({ data, pageContext, location }) => {
  let language
  if (pageContext && pageContext.language) {
    language = pageContext.language
  }
  const post = data.singleCommunity.nodes[0]

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)

  const toggleDialog = prop => {
    setIsDialogOpen(!isDialogOpen)
    setGalleryIndex(prop)
  }

  const handleYearChange = prop => event => {
    setYears({
      ...years,
      [prop]: event.target.checked
    })
  }

  let photos = {}
  const stateYears = {}
  let displayedPhotos = []
  var [years, setYears] = useState()

  if (post.gallery.usesYears && post.gallery.years) {
    // get photos for set year and create array of photos in photos object
    for (let i = 0; i < post.gallery.years.length; i++) {
      photos[post.gallery.years[i]] = getClientCloudinaryMedia(
        false,
        post.gallery.prefix + post.gallery.years[i],
        "media"
      )
      stateYears[post.gallery.years[i]] = false
      if (i === post.gallery.years.length - 1)
        stateYears[post.gallery.years[i]] = true
    }
    ;[years, setYears] = useState(stateYears)
    for (let i = 0; i < post.gallery.years.length; i++) {
      const year = post.gallery.years[i]
      if (photos[year] && years[year]) {
        displayedPhotos = displayedPhotos.concat(photos[year])
      }
    }
  } else if (post.gallery.prefix) {
    photos = getClientCloudinaryMedia(false, post.gallery.prefix, "media")
    displayedPhotos = photos
  }

  const reactGalleryPhotos = function (photos) {
    const arr = []
    for (let i = 0; i < photos.length; i++) {
      const obj = {}
      obj.original = `/${photos[i]}.jpg`
      arr.push(obj)
    }
    return arr
  }

  const playIcon = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="play"
      className="svg-inline--fa fa-play fa-w-14 button-icon"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
      />
    </svg>
  )

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          lang={language}
        />

        <div className="single-community">
          <Button buttonText="BACK" goBack href="/community/" />

          <div className="columns top-section white-back">
            <div className="column is-2" />
            <div className="column">
              <Text
                as="h1"
                className="mobile-left"
                style={{ textAlign: "center" }}
                text={post.heading}
              />

              {post.blurb && (
                <div className="columns">
                  <div className="column is-3" />
                  <div className="column">
                    <Text
                      text={post.blurb}
                      className="large has-text-centered"
                    />
                  </div>
                  <div className="column is-3" />
                </div>
              )}
            </div>
            <div className="column is-2" />
          </div>

          {post.mainVideoImagePair && (
            <div
              className="columns has-text-centered"
              style={{ paddingBottom: "4rem" }}>
              <div className="column is-3" />
              <div className="column is-full-width">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.mainVideoImagePair.youtube}`}
                  controls
                  playing
                  overVideo>
                  <Image publicId={post.mainVideoImagePair.imageId} />
                </TheaterVideo>
              </div>
              <div className="column is-3" />
            </div>
          )}

          {post.mediaCoverage.images && post.mediaCoverage.images.length > 0 && (
            <section className="body-section color-back media-coverage">
              <div className="columns has-text-centered">
                <div className="column">
                  <Text as="h2" text={post.mediaCoverage.heading} />
                </div>
              </div>

              {post.mediaCoverage.images &&
              post.mediaCoverage.images.length === 1 ? (
                <div className="columns is-desktop community-row">
                  <div className="column" />
                  <div className="column image-button-wrapper">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover-link"
                      href={post.mediaCoverage.images[0].href}
                      style={{
                        display: "block",
                        maxWidth: "250px",
                        marginInline: "auto"
                      }}>
                      <Image
                        useAR
                        publicId={post.mediaCoverage.images[0].image}
                      />
                    </a>
                    {post.useButton ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={post.mediaCoverage.images[0].href}
                        className="standard-button image-button mx-auto">
                        Learn More
                      </a>
                    ) : post.mediaCoverage.images[0].href.includes("youtube") ||
                      post.mediaCoverage.images[0].href.includes("youtu.be") ? (
                      <a
                        className="standard-button contained button-video"
                        style={{ marginTop: ".6666rem" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={post.mediaCoverage.images[0].href}>
                        {playIcon} Watch Video
                      </a>
                    ) : (
                      <a
                        className="standard-button contained mx-auto"
                        style={{ marginTop: ".6666rem" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={post.mediaCoverage.images[0].href}>
                        Learn More
                      </a>
                    )}
                  </div>
                  <div className="column" />
                </div>
              ) : (
                <Grid pages={post.mediaCoverage.images} rowsOf={2} />
              )}
            </section>
          )}

          {post.gallery.prefix && (
            <section className="body-section community-gallery">
              <div className="columns has-text-centered">
                <div className="column is-3" />
                <div className="column">
                  <h2
                    style={{
                      marginTop:
                        post.mediaCoverage.images &&
                        post.mediaCoverage.images.length === 0
                          ? "0"
                          : "40px"
                    }}>
                    Gallery
                  </h2>
                  {post.gallery.usesYears && (
                    <div className="years-container">
                      <ul className="years-list">
                        {post.gallery.years.map(year => (
                          <li key={year}>
                            <div className="time-check-container">
                              <div className="time-check">
                                <input
                                  onChange={handleYearChange(year)}
                                  type="checkbox"
                                  checked={years[year]}
                                  id={`time-check-${year}.`}
                                  value={`year-${year}`}
                                />
                                <label htmlFor={`time-check-${year}.`}>
                                  {year}
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="column is-3" />
              </div>

              <div className="columns has-text-centered">
                <div className="column">
                  <GridGallery
                    pages={displayedPhotos}
                    rowsOf={3}
                    toggleDialog={toggleDialog}
                  />
                  {post.gallery.bottomBlurb && (
                    <h4>{post.gallery.bottomBlurb}</h4>
                  )}
                </div>
              </div>
            </section>
          )}

          <section className="body-section community-get-started cta">
            <GetStarted
              sideColumnIs={2}
              centerHeading
              centerText
              heading={post.getStarted.heading}
              buttons={post.getStarted.buttons}
              paragraph={post.getStarted.blurb}
            />
          </section>

          <div>
            <Dialog
              className="community-dialog"
              lazy={false}
              backdropClassName="dark-background"
              portalClassName="show-cursor community-portal"
              isOpen={isDialogOpen}
              onClose={toggleDialog}>
              <CloseIcon onClick={toggleDialog} />
              <DialogGallery
                galleryIndex={galleryIndex}
                images={reactGalleryPhotos(displayedPhotos)}
              />
            </Dialog>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SingleCommunity

function Grid(props) {
  // Split into rows of 2
  const chunk_size = props.rowsOf
  const arr = props.pages
  const groups = arr
    .map((e, i) => (i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null))
    .filter(e => e)

  return groups.map((row, i) => (
    <GridRow key={`media-coverage-row-${i}`} items={row} />
  ))
}

function GridRow(props) {
  const playIcon = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="play"
      className="svg-inline--fa fa-play fa-w-14 button-icon"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
      />
    </svg>
  )

  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column" />}
            <div
              className={`column image-button-wrapper ${
                itemLen === i + 1 ? "is-4" : "is-4"
              }`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hover-link mx-auto"
                href={item.href}
                style={{ display: "block" }}>
                <Image useAR publicId={item.image} />
              </a>
              {props.useButton ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                  className="standard-button image-button">
                  Learn More
                </a>
              ) : item.href.includes("youtube") ||
                item.href.includes("youtu.be") ? (
                <a
                  className="standard-button contained button-video mx-auto"
                  style={{ marginTop: ".6666rem" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}>
                  {playIcon} Watch Video
                </a>
              ) : (
                <a
                  className="standard-button contained mx-auto"
                  style={{ marginTop: ".6666rem" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}>
                  Learn More
                </a>
              )}
            </div>
            {i === 0 && <div className="column is-2" />}
            {i === 1 && <div className="column" />}
          </>
        )
      })}
    </div>
  )
}

function GridGallery(props) {
  // Split into rows of 2
  const chunk_size = props.rowsOf
  const arr = props.pages
  const groups = arr
    .map((e, i) => (i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null))
    .filter(e => e)

  return groups.map((row, i) => (
    <GridRowGallery
      toggleDialog={props.toggleDialog}
      rowIndex={i - 1}
      key={`gallery-row-${i}`}
      items={row}
    />
  ))
}

function GridRowGallery(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        if (itemLen === 1) {
          return (
            <>
              <div className="column is-2" />
              <div className="column gallery-column first">
                <Image
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                />
              </div>
              <div
                className="column gallery-column vis-hidden"
                style={{ visibility: "hidden" }}>
                <Image
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                />
              </div>
              <div
                className="column gallery-column vis-hidden last"
                style={{ visibility: "hidden" }}>
                <Image
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                />
              </div>
              <div className="column is-2" />
            </>
          )
        }
        if (itemLen === 2) {
          return (
            <>
              {i === 0 && <div className="column is-2" />}
              <div
                className={`column gallery-column ${
                  i === itemLen - 1 ? "last" : i === 0 ? "first" : ""
                } ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}>
                <Image
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                />
              </div>
              {i === 1 && (
                <div
                  className="column gallery-column vis-hidden"
                  style={{ visibility: "hidden" }}>
                  <Image
                    onClick={() => {
                      props.toggleDialog(i + 3 * props.rowIndex)
                    }}
                    className="gallery-image"
                    publicId={item}
                    dpr="auto"
                    aspectRatio="16:9"
                    crop="fill"
                    gravity="faces"
                    responsiveUseBreakpoints="true"
                  />
                </div>
              )}
              {i === 1 && <div className="column is-2" />}
            </>
          )
        }
        return (
          <>
            {i === 0 && <div className="column is-2" />}
            <div
              className={`column gallery-column ${
                i === itemLen - 1 ? "last" : i === 0 ? "first" : ""
              } ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}>
              <Image
                onClick={() => {
                  props.toggleDialog(i + 3 * props.rowIndex)
                }}
                className="gallery-image"
                publicId={item}
                dpr="auto"
                aspectRatio="16:9"
                responsive
                crop="fill"
                gravity="faces"
                responsiveUseBreakpoints="true"
              />
            </div>
            {i === 2 && <div className="column is-2" />}
          </>
        )
      })}
    </div>
  )
}

function DialogGallery(props) {
  return (
    <ImageGallery
      items={props.images}
      showThumbnails={false}
      showFullscreenButton={false}
      startIndex={props.galleryIndex + 3}
      lazyLoad={false}
    />
  )
}

export const query = graphql`
  query ($title: String!) {
    singleCommunity: allCommunitiesJson(filter: { title: { eq: $title } }) {
      nodes {
        blurb
        gallery {
          shortcode
          bottomBlurb
          prefix
          usesYears
          years
        }
        getStarted {
          buttons {
            button {
              appearance
              buttonText
              href
            }
          }
          heading
          blurb
        }
        heading
        id
        mainVideoImagePair {
          youtube
          imageId
        }
        mediaCoverage {
          images {
            image
            href
          }
          heading
        }
        metaDescription
        metaTitle
        searchHeading
        title
      }
    }
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
  }
`
